import { useState } from "react";
import { MenuContextValue, MenuKeyProps } from "./Interface";

export const useProvideMenu = (): MenuContextValue => {
  const [menu, setMenu] = useState<MenuKeyProps>("");
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const setMenuKey = (menuKey: MenuKeyProps): void => {
    setMenu(menuKey);
  };

  const shouldShowMenu = (shouldShow: boolean): void => {
    setShowMenu(shouldShow);
  };

  return {
    menuKey: menu,
    setMenuKey,
    showMenu,
    shouldShowMenu,
  };
};
