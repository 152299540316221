import React, { createContext } from "react";
import { ChildrenProp } from "../../Interface";
import { MenuContextValue } from "./Interface";
import { useProvideMenu } from "./useProvideMenu";

const MenuContext = createContext<MenuContextValue>({} as MenuContextValue);

const MenuProvider: React.FC<ChildrenProp> = ({ children }) => {
  const menu = useProvideMenu();
  return <MenuContext.Provider value={menu}>{children}</MenuContext.Provider>;
};

export { MenuProvider, MenuContext };
