import React, { createContext } from "react";
import { ChildrenProp } from "../../Interface";
import { AuthContextValue } from "./AuthInterface";
import { useProvideAuth } from "./useProvideAuth";

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

const AuthProvider: React.FC<ChildrenProp> = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
