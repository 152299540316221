import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import classNames from "classnames";

interface SpinnerProps {
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
  className,
}): React.ReactElement => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div
      data-cy="spinner"
      data-testid="spinner"
      className={classNames("flex justify-center items-center", className)}
    >
      <Spin indicator={antIcon} />
    </div>
  );
};
