export interface ChildrenProp {
  children: React.ReactNode;
}

export const GenericErrorTitle = "Unknown Error";
export const GenericErrorDesc =
  "Sorry! There was an unexpected error. Please try again.";

export const BackgroundDocTitle = "This document is being analyzed";
export const BackgroundDocDesc =
  "This document exceeds the upload limitations. Hence it is being analyzed in the background. The document will be available to edit once the analysis is complete.";

export const BgColors = [
  "bg-blue-600",
  "bg-teal-600",
  "bg-purple-600",
  "bg-gray-600",
  "bg-yellow-600",
  "bg-violet-600",
];

export interface Options {
  id: string;
  text: string;
}

export interface QuestionData {
  text: string;
  qNo: number;
  id?: string;
  options: Options[];
}

export interface ChartData {
  label: string[];
  value: number[];
}

export interface LevelScoreData {
  name: string;
  scores: { [level: string]: number };
}

export interface ModuleInsightResp {
  name: string;
  insights: ModuleInsights;
  help: ModuleInsightFields[];
}

export interface ModuleInsights {
  title: string;
  fields: ModuleInsightFields[];
}

export interface ModuleInsightFields {
  title: string;
  description: string[];
}

export interface MyDocument {
  analysis_status: string;
  docId: string;
  filename: string;
  is_background: boolean;
  last_modified: string;
  preview_content: string;
}

export interface GenericSuccessResp {
  title?: string;
  description?: string;
}

export interface GenericErrorResp {
  title?: string;
  desc?: string;
}

export interface DocumentPath {
  path: string;
}

export interface LocationDetails {
  country?: string;
}
