import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ContextRoutes } from "./Routes/ContextRoutes";
import { Spinner } from "./Shared/Components/Spinner";
import { useAuth } from "./Shared/Hooks/useAuth";

interface AppProps {
  children?: JSX.Element;
}

export const App: React.FC<AppProps> = ({ children }): React.ReactElement => {
  const { authenticating, verifyLogin } = useAuth();

  useEffect(() => {
    verifyLogin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {authenticating ? (
        <Spinner />
      ) : (
        <React.Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/*" element={<ContextRoutes />}></Route>
            <Route path="*" element={<Navigate to="/" />}></Route>
          </Routes>
          {children}
        </React.Suspense>
      )}
    </>
  );
};
