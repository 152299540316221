import axios from "axios";
import { logout } from "../utils";

interface GetProps {
  url: string;
  signal?: AbortSignal;
}

interface PostProps extends GetProps {
  data: unknown;
}

const headers = {
  "Content-Type": "application/json",
};

const withCredentials = true;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Get = <T>({ url, signal }: GetProps): Promise<void | T> => {
  return axios
    .get(url, {
      signal,
      headers: { ...headers, Authorization: localStorage.getItem("token") },
      withCredentials,
    })
    .then((response) => {
      return response.data?.data as T;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        if (!url.startsWith("/auth/")) {
          logout();
        }
      }
      throw err;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Post = <T>({
  url,
  signal,
  data,
}: PostProps): Promise<void | T> => {
  return axios
    .post(url, data, {
      signal,
      headers: { ...headers, Authorization: localStorage.getItem("token") },
      withCredentials,
    })
    .then((response) => {
      return response.data?.data as T;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        if (!url.startsWith("/auth/")) {
          logout();
        }
      }
      throw err;
    });
};

export const DownloadExcel = ({ data, url }: PostProps) => {
  return axios({
    method: "post",
    url: url,
    data: data,
    responseType: "arraybuffer",
    withCredentials: true,
  });
};
