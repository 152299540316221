import { useState } from "react";
import axios from "axios";
import {
  AuthContextValue,
  SignInProps,
  UserInfo,
  LoginResp,
} from "./AuthInterface";
import { useNotify } from "../../Hooks/useNotify";
import { Post } from "../../Fetch";
import { logout } from "../../utils";
import { useSearchParams } from "react-router-dom";

export const useProvideAuth = (): AuthContextValue => {
  const { ErrorNotify } = useNotify();
  const [searchParams] = useSearchParams();

  // const localState = localStorage.getItem("user");
  // let initState: AuthState = {} as AuthState;
  // if (localState) {
  //   try {
  //     const parsedLocalState = JSON.parse(localState);
  //     if (isAuthState(parsedLocalState)) initState = parsedLocalState;
  //   } catch {}
  // }
  // const [user, setUser] = useState<AuthState>(initState);

  const [authenticating, setAuthenticating] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const verifyLogin = () => {
    setAuthenticating(true);

    if (searchParams.get("swayamuser") || searchParams.get("bitslawuser")) {
      setIsAuthenticated(false);
      setAuthenticating(false);
    } else {
      const rt = localStorage.getItem("rt");
      if (rt) {
        setIsAuthenticated(true);
        setAuthenticating(false);
      } else {
        setIsAuthenticated(false);
        setAuthenticating(false);
      }
    }
  };

  const verifySession = () => {
    // TODO: Use common Get method once new auth backend is implemented.
    setAuthenticating(true);
    axios
      .request({
        url: "/api/user_info",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const resp = res.data as UserInfo;
        if (resp?.status_code === "01") {
          setIsAuthenticated(true);
          setAuthenticating(false);
        } else {
          setIsAuthenticated(false);
          setAuthenticating(false);
          ErrorNotify(
            {
              title: "Access unauthorized",
              desc: "You've been logged out. Please login again.",
            },
            false
          );
        }
      })
      .catch((err) => {
        setIsAuthenticated(false);
        setAuthenticating(false);
        ErrorNotify(err);
      });
  };

  const signin = async ({ username, password }: SignInProps): Promise<void> => {
    Post<LoginResp>({
      url: "/auth/Login",
      data: { username, password },
    })
      .then((res) => {
        if (res) {
          localStorage.setItem("token", res.token);
          localStorage.setItem("rt", res.refreshtoken);
          setIsAuthenticated(true);
        }
      })
      .catch((err) => {
        ErrorNotify(err);
      });
  };

  const signout = () => {
    Post<unknown>({
      url: "/auth/Logout",
      data: {},
    });
    setIsAuthenticated(false);
    logout(false);
  };

  return {
    signin,
    signout,
    authenticating,
    isAuthenticated,
    verifyLogin,
    verifySession,
  };
};
