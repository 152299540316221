import React from "react";
import { MenuProvider } from "../Shared/Context/Menu/MenuContext";

import { useAuth } from "../Shared/Hooks/useAuth";

const Authenticated = React.lazy(() =>
  import("../Modules/Authenticated").then((module) => ({
    default: module.Authenticated,
  }))
);
const UnAuthenticated = React.lazy(() =>
  import("../Modules/UnAuthenticated").then((module) => ({
    default: module.UnAuthenticated,
  }))
);

export const ContextRoutes = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div>
      {isAuthenticated ? (
        <MenuProvider>
          <Authenticated />
        </MenuProvider>
      ) : (
        <UnAuthenticated />
      )}
    </div>
  );
};
