import React, { createContext } from "react";
import { notification } from "antd";
import {
  ChildrenProp,
  GenericErrorDesc,
  GenericErrorTitle,
} from "../../Interface";
import { NotifyContextValue, NotifyProps } from "./interface";

const useProvideNotify = (): NotifyContextValue => {
  const [api, AntNotifyContext] = notification.useNotification();

  const Notify = ({ title, desc, type }: NotifyProps) => {
    api[type]({
      message: title,
      description: desc,
      placement: "top",
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ErrorNotify = (err: any, resp = true) => {
    let errResp = err;
    if (resp) {
      errResp = err?.response?.data?.error;
    }
    Notify({
      title: errResp?.title || GenericErrorTitle,
      desc: errResp?.desc || GenericErrorDesc,
      type: "error",
    });
  };

  return {
    AntNotifyContext,
    Notify,
    ErrorNotify,
  };
};

const NotifyContext = createContext<NotifyContextValue>(
  {} as NotifyContextValue
);

const NotificationProvider: React.FC<ChildrenProp> = ({ children }) => {
  const notify = useProvideNotify();
  return (
    <NotifyContext.Provider value={notify}>
      <>
        {notify.AntNotifyContext}
        {children}
      </>
    </NotifyContext.Provider>
  );
};

export { NotificationProvider, NotifyContext };
